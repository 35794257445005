@use 'global-styles/marble/base/base.scss';

.list {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xs);
}

.listItem {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-micro);
	justify-content: space-between;

	@media only screen and (min-width: base.$breakpoint-m) {
		align-items: center;
		flex-direction: row;
		gap: var(--spacing-xxs);
	}
}

.title {
	font-size: var(--textsize-s);
	font-weight: 500;
}

.titleBox {
	max-width: max(60%, 750px);
}

.description {
	color: var(--color-grey-800);
	font-size: var(--textsize-xs);
}

.links {
	display: flex;
	flex-wrap: nowrap;
	gap: var(--spacing-micro);
	justify-self: flex-end;
	white-space: nowrap;
}

.icon {
	margin-bottom: 0.2em;
}
