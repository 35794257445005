@use 'global-styles/marble/base/base.scss';

.banner {
	--text-color: var(--color-white);
	--color-text-header: var(--color-white);
	--notification-background: var(--color-met-red-dark);
	--notification-foreground: var(--color-white);
	align-items: center;
	background-color: var(--notification-background);
	color: var(--notification-foreground);
	display: flex;
	flex-direction: row;
	gap: var(--spacing-micro);
	justify-content: center;
	padding: var(--spacing-micro) var(--spacing-component-margin);
	position: relative;
	text-align: center;

	:focus-visible {
		background-color: var(--notification-foreground);
		color: var(--notification-background);
		outline-color: var(--notification-foreground);
	}
}

.text {
	align-items: center;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-micro);
	@media screen and (min-width: base.$breakpoint-m) {
		column-gap: var(--spacing-xs);
		flex-direction: row;
	}
}

.header {
	font-size: var(--textsize-s);
	font-weight: 500;
}

.icon {
	color: var(--notification-background);
	flex-shrink: 0;
	height: 24px;
	width: 24px;
	@media screen and (min-width: base.$breakpoint-m) {
		height: 1em;
		width: 1em;
	}
}
