@use 'global-styles/marble/base/base.scss';
@use 'sass:math';
@forward './tabs.scss';

.header {
	background: var(--color-component-background);
	display: flex;
	flex-direction: column;
	margin-bottom: var(--spacing-m);
	padding-top: var(--spacing-xs);
}

.dates {
	color: var(--color-text-header);
	font-size: var(--textsize-m);
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
}

.galleryList {
	font-weight: 400;
}

.row {
	display: flex;
	justify-content: space-between;
	padding: 0 var(--spacing-component-margin);
	width: 100%;
}

.rowTop {
	flex-direction: column;

	@media (min-aspect-ratio: 5/6) {
		flex-direction: row;
	}
}

.eyebrow {
	@include base.typography-tag();
	display: block;
	margin-bottom: var(--spacing-micro);

	&:empty {
		display: none;
	}
}

.statusModule {
	@media (min-aspect-ratio: 5/6) {
		flex-basis: 33%;
		flex-shrink: 0;
	}
}

.location {
	font-size: var(--textsize-xs);
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
}

.displayText {
	font-size: var(--textsize-xxs);
}

.heading {
	font-weight: 600;
	@media (min-aspect-ratio: 5/6) {
		margin-right: var(--spacing-xxs);
	}
}

.meta {
	margin-bottom: var(--spacing-xs);
}

.ctaContainer {
	align-items: center;
	column-gap: var(--spacing-xxs);
	display: flex;
	flex-basis: auto;
	flex-grow: 1;
	padding-bottom: var(--spacing-xxs);
	@media (min-aspect-ratio: 5/6) {
		padding-bottom: 0;
	}
}

.rowBottom {
	display: flex;
	flex-direction: column;
	padding: 0;
	scroll-margin-top: 80px; //TODO masthead height variable eventually
	@media (min-aspect-ratio: 5/6) {
		padding-left: var(--spacing-component-margin);
		padding-right: var(--spacing-component-margin);
	}
}

.tabsContainer {
	background-color: var(--color-white);
	border-bottom: 1px solid var(--color-divider);
	flex-basis: 100%;
	flex-grow: 1;
	max-width: 100%;
	padding: var(--spacing-micro) 0;
	position: relative;

	@media (min-aspect-ratio: 5/6) {
		background-color: transparent;
		border-bottom: 0;
		box-shadow: none;
		padding: 0;
	}

	&::after,
	&::before {
		bottom: 0;
		content: '';
		position: absolute;
		top: 0;
		width: var(--spacing-component-margin);
		@media (min-aspect-ratio: 5/6) {
			content: none;
		}
	}
}

.descriptor {
	color: currentColor;
	display: block;
	font-size: var(--textsize-xxs);
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0.7px;
	margin-bottom: var(--spacing-micro);
	text-transform: uppercase;
}
