@use 'global-styles/marble/base/base.scss';

.message {
	align-items: center;
	background-color: var(--color-purple);
	border-radius: base.$radius-sharp;
	color: var(--color-white);
	display: flex;
	font-size: var(--textsize-xs);
	font-weight: 500;
	padding: var(--spacing-micro);
	width: 100%;
}

.icon {
	align-items: center;
	align-self: center;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	line-height: 1;
	margin-right: var(--spacing-micro);
}
