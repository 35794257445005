@use 'global-styles/marble/base/base.scss';

.tabs {
	display: block;
	max-width: 100%;
	overflow: auto;
	padding: 0 var(--spacing-component-margin);
	white-space: nowrap;
	@media (min-aspect-ratio: 5/6) {
		padding: 0;
		white-space: normal;
	}
}

.tab {
	border-bottom: 3px solid transparent;
	display: inline-block;
	font-weight: 500;
	margin: var(--spacing-micro) var(--spacing-xxs) var(--spacing-micro) 0;
	text-decoration: none;
	white-space: nowrap;
	z-index: base.z('negative');

	&:last-child {
		margin-right: 0;
		@media (min-aspect-ratio: 5/6) {
			margin-right: var(--spacing-micro);
		}
	}

	&.isActive {
		border-color: var(--color-link);
	}

	&:focus,
	&:hover {
		border-color: var(--color-met-red-medium);
		cursor: pointer;
		text-decoration: none;
	}

	&:focus-visible:not(.is-active) {
		background-color: var(--color-focus-state-background);
	}
}
