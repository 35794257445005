@use 'global-styles/marble/base/base.scss';

.quotesModule {
	margin: var(--spacing-m) var(--spacing-component-margin);
}

.header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--spacing-xs);

	h2 {
		margin: 0;
	}
}

.quotes {
	column-gap: var(--spacing-s);
	display: grid;
	grid-template-columns: 1fr;
	margin-bottom: var(--spacing-s);
	row-gap: var(--spacing-s);

	@media screen and (min-width: base.$breakpoint-m) {
		grid-template-columns: repeat(3, 1fr);
	}

}

.quoteText {
	font-family: var(--austin);
	font-size: var(--textsize-m);
	margin-bottom: var(--spacing-micro);

	p {
		display: inline;
	}

	&::before {
		content: '“';
	}

	&::after {
		content: '”';
	}
}

.quoteCitation {
	display: flex;
	font-weight: 600;

	&::before {
		content: '—';
	}
}

.quoteLink {
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}
