.map {
	align-items: center;
	border: 1px solid var(--color-grey-900);
	border-radius: 4px;
	display: flex;
	height: 50vh;
	justify-content: center;
	max-width: 100%;
	overflow: hidden;

	:global(.case) {
		cursor: pointer;
		transition: filter 0.2s linear;

		/* stylelint-disable-next-line max-nesting-depth */
		&:hover {
			filter: brightness(0.9);
		}
	}
}

.svgWrapper svg {
	background-color: var(--color-site-background);
	height: auto;
	max-height: calc(50vh);
	max-width: 100%;
	padding: var(--spacing-micro);
	width: auto;
}
