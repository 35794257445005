@use 'global-styles/marble/base/base.scss';

.page {
	display: flex;
	height: var(--max-page-height);
}

.chapter {
	align-items: center;
	display: flex;
	flex-basis: 50%;
	flex-direction: column;
	flex-grow: 0;
	flex-shrink: 0;
	justify-content: center;
	padding: var(--spacing-xl);
	position: relative;
	text-align: center;
	z-index: base.z('positive-default');
}

.tag {
	font-size: var(--textsize-l);
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
	text-transform: uppercase;
}

.imageWrapper {
	bottom: 0;
	filter: brightness(0.5);
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: base.z('negative');
}

.readMore {
	align-items: center;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	font-size: var(--textsize-m);
	font-weight: 500;
	gap: var(--spacing-xxs);
	justify-content: center;
	text-align: center;
	z-index: base.z('positive-default');
	
	canvas {
		min-height: 200px;
	}
}

.readMoreText {
	font-size: var(--textsize-l);
	width: 340px;
}

.fixed {
	left: 50%;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
}
