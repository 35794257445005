@use 'global-styles/marble/base/base';
$mini-player-image-size: 120px;

.mediaSection {
	.mini-player & {
		flex-direction: row;
		min-height: $mini-player-image-size;
		padding: 0;
	}
}

.headings {
	.mini-player & {
		line-height: 1;
	}
}

.body {
	.mini-player & {
		padding: var(--spacing-micro);
	}
}

.title {
	.mini-player & {
		display: inline;
		font-size: var(--textsize-xs);
	}
}

.subtitle {
	.mini-player & {
		display: inline;
		font-size: var(--textsize-xs);
	}
}

.imageSection {
	.mini-player & {
		flex-basis: $mini-player-image-size;
		flex-grow: 0;
		flex-shrink: 0;
		margin: 0;
	}
}

.imageWrapper {
	.mini-player & {
		min-width: 0;
	}
}

.transcriptToggle {
	.mini-player & {
		padding: var(--spacing-micro);
	}
}

.seekBack,
.seekForward {
	.mini-player & {
		display: none;
	}
}

.playWrapper {
	.mini-player & {
		align-items: center;
		display: flex;
		font-size: var(--textsize-xl);
		height: $mini-player-image-size;
		justify-content: center;
		left: 0;
		position: absolute;
		text-align: center;
		top: 0;
		width: $mini-player-image-size;
	}
}
