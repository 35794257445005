//TODO deprecate in favor of <ContentSplit/>
@use 'global-styles/marble/base/base.scss';

.exhibitionTheme {
	display: grid;
	grid-template-columns: [main-start] var(--spacing-component-margin)
		[content-start] 2fr
		[aside-start] 1fr
		[content-end] var(--spacing-component-margin)
		[main-end];
	grid-template-rows: [main-start] auto
		[content-start] auto
		[aside-start] auto
		[content-end] auto
		[main-end];
	margin-bottom: var(--spacing-m);
	margin-top: var(--spacing-m);
}

.main,
.aside {
	grid-column-end: content-end;
	grid-column-start: content-start;
	margin-bottom: var(--spacing-s);
}

.main {
	@media screen and (min-width: base.$breakpoint-m) {
		grid-column-end: aside-start;
		grid-column-start: content-start;
		margin-bottom: var(--spacing-m);
		padding-right: var(--spacing-l);
	}
}

.aside {
	@media screen and (min-width: base.$breakpoint-m) {
		grid-column-end: content-end;
		grid-column-start: aside-start;
		padding-right: var(--spacing-m);
	}
}


.sliderWrapper {
	grid-column-end: main-end;
	grid-column-start: main-start;
}

.exhibition-theme__text:not(:empty) {
	margin-bottom: var(--spacing-xxs);
}

.exhibition-theme__svg-wrapper {
	position: sticky;
	top: 20px;

	svg {
		height: auto;
		max-width: 100%;
	}
}

.shelf {
	grid-column-end: main-end;
	grid-column-start: main-start;
}

.title {
	margin-bottom: var(--spacing-micro);
}

.subtitle {
	margin-bottom: var(--spacing-micro);
}
