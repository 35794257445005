@use 'global-styles/marble/base/base.scss';

.card {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.title {
	line-height: 1.25;
}

.seriesAndType {
	align-items: normal;
	color: var(--color-text);
	display: flex;
	gap: var(--spacing-micro);
	line-height: normal;
	@include base.typography-tag();
}

.date {
	color: var(--color-text);
	font-size: var(--textsize-xs);
	font-weight: 500;
}

.type {
	align-items: normal;
	display: inline-flex;
	gap: 4px;
	line-height: normal;
}

.link { //TODO work with Julie to see if theres room to change this pattern.
	text-decoration: none;

	&:hover,
	&:focus-visible {
		text-decoration: underline;
	}
}
