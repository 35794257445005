@use 'global-styles/marble/base/sizes.scss';

.exhibition-catalogue {
	border-radius: 8px;
	box-shadow: 0 1px 6px 2px var(--color-card-shadow);
	column-gap: var(--spacing-micro);
	display: flex;
	margin: 6px;
	max-width: 500px;
	padding: var(--spacing-xxs);
}

.exhibition-catalogue__image-wrapper {
	flex: 1;

	img {
		height: auto;
		width: 100%;
	}
}

.exhibition-catalogue__text {
	flex: 2;
}

.exhibition-catalogue__title {
	margin-bottom: var(--spacing-micro);
}

.exhibition-catalogue__body {
	font-size: var(--textsize-xs);
	margin-bottom: var(--spacing-micro);
}

.exhibition-catalogue__link {
	font-size: var(--textsize-xs);
}
