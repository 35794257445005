@use 'global-styles/marble/base/base.scss';

$color-background: var(--color-blue-600);
$color-text: var(--color-blue-100);

.notificationsContainer {
	margin: var(--spacing-s) var(--spacing-component-margin);
}

.inlineNotification {
	background-color: $color-background;
	border-radius: base.$radius-blunt;
	margin-bottom: var(--spacing-xxs);
	padding: var(--spacing-xxs);
}

.notificationIcon {
	margin-right: var(--spacing-micro);
	vertical-align: top;
}

.header {
	color: var(--color-grey-900);
	font-size: var(--textsize-m);
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
}

.message {
	color: var(--color-grey-900);
	font-size: var(--textsize-xs);
	font-weight: 400;

	@media screen and (min-width: base.$breakpoint-s) {
		margin-left: calc(18px + var(--spacing-micro));
	}
}
