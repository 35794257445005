@use 'global-styles/marble/base/base.scss';

.hotspotSection {
	margin-bottom: var(--spacing-xxs);
	margin-top: var(--spacing-xxs);
	scroll-margin-top: 80px; //TODO masthead height var;
}

.hotspotViewer {
	max-width: 500px;
	position: relative;

	@media (min-width: base.$breakpoint-m) {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		max-width: 100%;
	}
}

.wrapper {
	border: 5px solid var(--color-black-transparency);
	overflow: hidden;
	position: relative;
	@media (min-width: base.$breakpoint-m) {
		flex-basis: 50%;
		flex-grow: 0;
	}
}

.imageWrapper {
	transform: scale(1);
	transform-origin: 50% 50%;
	transition: all 0.5s;
}

.image {
	width: 100%;
}

.hotspot {
	@include base.button-reset();
	background: var(--color-black-opaque-50);
	border-radius: 50%;
	cursor: pointer;
	display: block;
	height: 20px;
	position: absolute;
	transition: all 0.2s;
	width: 20px;

	&:focus-visible,
	&:hover {
		background: var(--color-black-transparency);
		opacity: 1;
	}

	[data-zoomed='true'] & {
		opacity: 0.5;
	}
}

.hotSpotInfo {
	backdrop-filter: blur(5px);
	background-color: var(--color-black-transparency);
	color: var(--color-white);
	line-height: 1.2;
	padding: var(--spacing-micro);
	position: sticky;
	top: 0;

	@media (min-width: base.$breakpoint-m) {
		flex-basis: 50%;
		flex-grow: 0;
	}
}

.hotSpotTitle {
	align-items: flex-start;
	border-bottom: 1px solid currentColor;
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--spacing-micro);
}

.closeButton {
	@include base.button-reset();
	font-size: var(--textsize-m);
	font-weight: 300;
	padding: 4px;
	transition: opacity 0.2s;
	
	&:hover,
	&:focus {
		opacity: 0.4;
	}
}
