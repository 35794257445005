@use 'global-styles/marble/base/base.scss';

.activeFilters {
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-micro);
}

.activeFilter {
	@include base.button-reset;
	align-items: center;
	background-color: var(--color-grey-900);
	border-radius: 50px;
	color: var(--color-white);
	display: flex;
	flex-wrap: nowrap;
	font-size: var(--textsize-xxs);
	line-height: 1;
	padding: 4px var(--spacing-micro);

	&::after {
		content: '\00d7';
		font-size: 1.5em;
		font-weight: 600;
		margin-left: var(--spacing-micro);
	}

	&:focus,
	&:hover {
		background-color: var(--color-grey-700);
		outline: none;
	}
}
