.wrapper {
	margin: var(--spacing-m) 0;
}

.clampedWidthWrapper {
	margin: var(--spacing-m) auto;
	max-width: var(--productive-width-max);
}

.divider {
	border: 0;
	border-top: 1px solid var(--color-divider);
	display: block;
	height: 1px;
	max-width: var(--productive-width-max);
	padding: 0;
}
