@use 'global-styles/marble/base/base.scss';
@use 'global-styles/marble/base/sizes.scss';

.collectionObject:focus-within {
	.imageWrapper {
		background-color: var(--color-blue-600);
	}
}

.imageWrapper {
	--collection-image-height: 350px;
	--collection-image-width: 250px;
	align-items: center;
	background-color: var(--color-grey-050);
	color: var(--color-grey-900);
	display: flex;
	height: var(--collection-image-height);
	line-height: 0;
	margin-bottom: var(--spacing-micro);
	min-height: var(--collection-image-height);
	min-width: var(--collection-image-width);
	position: relative;

	&:hover {
		background-color: var(--color-grey-100);
		box-shadow: inset 1px 0 0 0 var(--color-grey-200);
	}
}

.image {
	height: auto;
	margin: auto;
	max-height: 100%;
	max-width: 85vw;
	width: auto;
}

.gridView {
	max-width: 100%;
}

.title {
	font-size: var(--textsize-s);
	font-weight: 500;
	margin-bottom: 8px; //TODO make micro-smaller and use it here.
}

.link {
	text-decoration: none;

	/* stylelint-disable-next-line max-nesting-depth */
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.caption {
	line-height: 1.5;
}

.body {
	color: var(--color-text);
	font-size: var(--textsize-xs);
}

.culture {
	margin-bottom: 8px; //TODO make micro smaller and use that here too.
}

.noImageSvg {
	color: var(--color-grey-400);
	display: block;
	height: 78px;
	margin: auto;
	width: 150px;
}
