@use 'global-styles/marble/base/base.scss';

.card {
	background-color: var(--color-component-background);
	display: flex;
	flex-direction: column-reverse;
	padding: var(--spacing-component-margin);
	@media screen and (min-width: base.$breakpoint-m) {
		flex-direction: row;
	}
}

.main,
.imageWrapper {
	flex-basis: 50%;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0 auto;
	max-width: 500px;
	width: 100%;

	@media screen and (min-width: base.$breakpoint-m) {
		max-width: 100%;
	}
}

.title {
	margin-bottom: var(--spacing-xs);
}

.infoBox {
	background-color: var(--color-component-background-2);
	border-radius: base.$radius-soft;
	box-shadow: base.$shadow-passive;
	font-size: var(--textsize-xs);
	padding: var(--spacing-xxs);

	@media screen and (min-width: base.$breakpoint-m) {
		margin-right: var(--spacing-m);
	}
}

.row {
	display: flex;
	margin-bottom: var(--spacing-xxs);
}

.rowIcon {
	margin-right: var(--spacing-xxs);
	text-align: center;
	width: var(--spacing-xs);

	svg {
		height: var(--spacing-xs);
		width: auto;
	}
}

.rowTitle {
	font-weight: 500;
}

.buttonWrapper {
	align-items: center;
	display: flex;
	gap: var(--spacing-xxs);

	.button {
		font-size: var(--textsize-xs);
	}
}

.imageWrapper {
	margin-bottom: var(--spacing-s);
	@media screen and (min-width: base.$breakpoint-m) {
		margin-bottom: 0;
	}
}

.image {
	display: block;
	height: auto;
	width: 100%;
}
