.tabContainerBorder {
	border-bottom: 1px solid var(--color-divider);
	margin: 0 var(--spacing-component-margin);
}

.tabBodyWrapper {
	margin: 0 var(--spacing-component-margin) 0;
}

.seriesCredits {
	color: var(--color-grey-700);
	font-size: var(--textsize-xs);
}

.youtubeContainer {
	margin-bottom: var(--spacing-xs);
}
